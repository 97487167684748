import React, {useEffect, useLayoutEffect, useState} from "react";
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import {useModal} from "../context";
import Subscribe from "../components/modals/Subscribe";

export default function Home(){
    const {show, hide} = useModal();
    const [opacities, setOpacities] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [sliderRef, instanceRef] = useKeenSlider({
        slides: 3,
        initial: 0,
        loop: true,
        defaultAnimation: {
          duration: 1000
        },
        detailsChanged(s) {
            const new_opacities = s.track.details.slides.map((slide) => slide.portion)
            setOpacities(new_opacities)
        },
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
    });

    useEffect(() => {
        const handleScroll = event => {
            if(window.CookieConsent){
                const preferences = window.CookieConsent.getUserPreferences();
                if(preferences.acceptType === 'all' && !localStorage.getItem('subscribe3')){
                    show(<Subscribe/>, "modal--subscribe");
                    localStorage.setItem('subscribe3', 'true');
                }
            }

            /*if(!sessionStorage.getItem('subscribe2')){
                show(<Subscribe/>, "modal--subscribe");
                sessionStorage.setItem('subscribe2', 'true');
            }*/
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    return(
        <div className="content">
            <div className="sc__promo">
                <div onClick={(e) =>
                    e.stopPropagation() || instanceRef.current?.prev()
                } className="carousel-button carousel-button-prev">
                    <svg className="desk-only" width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Previous">
                        <g filter="url(#filter0_d_17_12219)">
                            <circle cx="28" cy="28" r="28" transform="rotate(180 28 28)" fill="#009FE5"></circle>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M32.3107 18.4041C32.3107 18.4041 23.763 22.7548 20.7674 25.6091C17.7717 28.4634 20.7674 30.3632 20.7674 30.3632L31.7897 37.8665C31.7897 37.8665 33.5391 38.4842 33.5391 36.2461C33.5391 34.0081 33.5391 32.4014 33.5391 32.4014C33.5391 32.4014 33.4614 30.7687 26.4043 28.4634C26.4043 28.4634 25.1153 27.6339 33.2101 23.5921C33.2101 23.5921 34.1949 22.7938 33.9498 20.4994C33.7047 18.2049 32.3107 18.4041 32.3107 18.4041Z"
                                  fill="white"></path>
                        </g>
                        <defs>
                            <filter id="filter0_d_17_12219" x="0" y="0" width="63" height="63"
                                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                               result="hardAlpha"></feColorMatrix>
                                <feOffset dx="7" dy="7"></feOffset>
                                <feComposite in2="hardAlpha" operator="out"></feComposite>
                                <feColorMatrix type="matrix"
                                               values="0 0 0 0 0.929412 0 0 0 0 0.898039 0 0 0 0 0.85098 0 0 0 1 0"></feColorMatrix>
                                <feBlend mode="normal" in2="BackgroundImageFix"
                                         result="effect1_dropShadow_17_12219"></feBlend>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_17_12219"
                                         result="shape"></feBlend>
                            </filter>
                        </defs>
                    </svg>
                </div>
                <div onClick={(e) =>
                    e.stopPropagation() || instanceRef.current?.next()
                } className="carousel-button carousel-button-next">
                    <svg className="desk-only" width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_259_35592)">
                            <circle cx="28" cy="28" r="28" fill="#009FE5"></circle>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M23.6893 37.5959C23.6893 37.5959 32.237 33.2452 35.2326 30.3909C38.2283 27.5366 35.2326 25.6368 35.2326 25.6368L24.2103 18.1335C24.2103 18.1335 22.4609 17.5158 22.4609 19.7539C22.4609 21.9919 22.4609 23.5986 22.4609 23.5986C22.4609 23.5986 22.5386 25.2313 29.5957 27.5366C29.5957 27.5366 30.8847 28.3661 22.7899 32.4079C22.7899 32.4079 21.8051 33.2062 22.0502 35.5006C22.2953 37.7951 23.6893 37.5959 23.6893 37.5959Z"
                                  fill="white"></path>
                        </g>
                        <defs>
                            <filter id="filter0_d_259_35592" x="0" y="0" width="63" height="63"
                                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                               result="hardAlpha"></feColorMatrix>
                                <feOffset dx="7" dy="7"></feOffset>
                                <feComposite in2="hardAlpha" operator="out"></feComposite>
                                <feColorMatrix type="matrix"
                                               values="0 0 0 0 0.929412 0 0 0 0 0.898039 0 0 0 0 0.85098 0 0 0 1 0"></feColorMatrix>
                                <feBlend mode="normal" in2="BackgroundImageFix"
                                         result="effect1_dropShadow_259_35592"></feBlend>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_259_35592"
                                         result="shape"></feBlend>
                            </filter>
                        </defs>
                    </svg>
                </div>
                <div ref={sliderRef} className="fader">
                    <div className="fader__slide" style={{ opacity: opacities[0] }}>
                        <div className="promo__it promo__it--3">
                            <div className="promo__it-img">
                                <div className="promo__it-img-wrap">
                                    <img src={require("../assets/img/promo-8.jpg")}/>
                                </div>

                                <div className="promo__it-ct-bg">
                                    <svg className="desk-only" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="2 0 104 618" width="106.4" height="618"><path fill="white" d="M0-5v675h22.4c-7-28.2-12-59-17-91-6-38.3-4-90.3 2-126 16.6-99.7 37-180.4 63-269 16.4-55.5 36-122.3 36-189H0z"/></svg>
                                    <svg className="mb-only" preserveAspectRatio="none" viewBox="0 0 375 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M267.188 0C186.979 0 146.458 29.375 92.7083 39.3229C38.9583 49.2708 0 31.25 0 31.25V42.5H375V21.3542C375 21.3542 335.495 0 267.188 0Z" fill="white"/>
                                    </svg>

                                </div>
                            </div>
                            <div className="promo__it-ct d-flex align-items-center">
                                <div className="promo__it-ct-bl">
                                    <div className="promo__it-title promo__it-title--over">
                                        Kinder Pingui <br/>
                                        Мандарин
                                    </div>
                                    <div className="promo__it-descr">
                                        Kinder Pingui Мандарин&nbsp;&mdash; это нежный бисквит с&nbsp;хрустящим шоколадом и&nbsp;молочно-мандариновой начинкой. Вкусный и&nbsp;яркий Kinder Pingui Мандарин прекрасно подойдет для зимнего легкого перекуса.
                                    </div>
                                    <div className="promo__it-action d-flex">
                                        <a href="https://xn--pingui-8nfa9a4b1bnd4b.xn--d1achhqu.xn--p1ai/" target="_blank" className="btn__promo d-flex align-items-center justify-content-center">
                                            Подробнее
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="promo__it-ct-bg2 desk-only">
                                <svg viewBox="0 0 1920 54" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#ffffff" d="M0.2,0 L0,54 L1920,54 L1919.9,0 C1919.9,0 1557,52.9 958.9,53.4 C360.8,53.9 0.2,0 0.2,0 Z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="fader__slide" style={{ opacity: opacities[1] }}>
                        <div className="promo__it promo__it--3">
                            <div className="promo__it-img">
                                <div className="promo__it-img-wrap">
                                    <img src={require("../assets/img/promo-6.png")}/>
                                </div>

                                <div className="promo__it-ct-bg">
                                    <svg className="desk-only" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="2 0 104 618" width="106.4" height="618"><path fill="white" d="M0-5v675h22.4c-7-28.2-12-59-17-91-6-38.3-4-90.3 2-126 16.6-99.7 37-180.4 63-269 16.4-55.5 36-122.3 36-189H0z"/></svg>
                                    <svg className="mb-only" preserveAspectRatio="none" viewBox="0 0 375 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M267.188 0C186.979 0 146.458 29.375 92.7083 39.3229C38.9583 49.2708 0 31.25 0 31.25V42.5H375V21.3542C375 21.3542 335.495 0 267.188 0Z" fill="white"/>
                                    </svg>

                                </div>
                            </div>
                            <div className="promo__it-ct d-flex align-items-center">
                                <div className="promo__it-ct-bl">
                                    <div className="promo__it-title promo__it-title--over">
                                        Собери <br/>
                                        их всех!
                                    </div>
                                    <div className="promo__it-descr">
                                        Участвуйте в&nbsp;веселых приключениях бегемотиков и&nbsp;помогите им&nbsp;собраться вместе.
                                    </div>
                                    <div className="promo__it-action d-flex">
                                        <a href="https://xn--90aefb9am2a6e.xn--d1achhqu.xn--p1ai/?utm_source=kinder&utm_medium=banner&utm_campaign=soberi_ih_vseh" target="_blank" className="btn__promo d-flex align-items-center justify-content-center">
                                            В путь!
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="promo__it-ct-bg2 desk-only">
                                <svg viewBox="0 0 1920 54" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#ffffff" d="M0.2,0 L0,54 L1920,54 L1919.9,0 C1919.9,0 1557,52.9 958.9,53.4 C360.8,53.9 0.2,0 0.2,0 Z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="fader__slide" style={{ opacity: opacities[2] }}>
                        <div className="promo__it">
                            <div className="promo__it-img">
                                <div className="promo__it-img-wrap">
                                    <img src={require("../assets/img/promo-1.avif")}/>
                                </div>

                                <div className="promo__it-ct-bg">
                                    <svg className="desk-only" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="2 0 104 618" width="106.4" height="618"><path fill="white" d="M0-5v675h22.4c-7-28.2-12-59-17-91-6-38.3-4-90.3 2-126 16.6-99.7 37-180.4 63-269 16.4-55.5 36-122.3 36-189H0z"/></svg>
                                    <svg className="mb-only" preserveAspectRatio="none" viewBox="0 0 375 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M267.188 0C186.979 0 146.458 29.375 92.7083 39.3229C38.9583 49.2708 0 31.25 0 31.25V42.5H375V21.3542C375 21.3542 335.495 0 267.188 0Z" fill="white"/>
                                    </svg>

                                </div>
                            </div>
                            <div className="promo__it-ct d-flex align-items-center">
                                <div className="promo__it-ct-bl">
                                    <div className="promo__it-title">
                                        Наша <br className="desk-only"/>
                                        миссия
                                    </div>
                                    <div className="promo__it-descr">
                                        Kinder поддерживает вас в&nbsp;воспитании счастливых детей, предлагая уникальные продукты и&nbsp;создавая радостные моменты, которые не&nbsp;забываются. <br/>
                                        Это важная составляющая в&nbsp;развитии детей, которая всегда была частью нашей истории.
                                    </div>
                                </div>
                            </div>

                            <div className="promo__it-ct-bg2 desk-only">
                                <svg viewBox="0 0 1920 54" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#ffffff" d="M0.2,0 L0,54 L1920,54 L1919.9,0 C1919.9,0 1557,52.9 958.9,53.4 C360.8,53.9 0.2,0 0.2,0 Z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                {loaded && instanceRef.current && (
                    <div className="fader__pagination d-flex justify-content-center">
                        {[
                            ...Array(instanceRef.current.track.details.slides.length).keys(),
                        ].map((idx) => {
                            return (
                                <button
                                    key={idx}
                                    onClick={() => {
                                        instanceRef.current?.moveToIdx(idx)
                                    }}
                                    className={"fader__pagination-dot" + (currentSlide === idx ? " active" : "")}
                                ></button>
                            )
                        })}
                    </div>
                )}
            </div>
            <div className="sc__development">
                <div className="container">
                    <div className="development__ct d-flex align-items-end justify-content-center">
                        <div className="logo__2">
                            <img src={require("../assets/img/logo.svg").default}/>
                        </div>
                        <div className="development__title">
                            Сайт находится в разработке
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}